export default [
    // {
    //     key: "index",
    //     sortable: false,
    //     text: "No",
    //     value: "index",
    //   },
    {    
        key: "label_code",
        sortable: false,
        text: "Kode Label",
        value: "label_code",
    },
    {
        key: "rel_tree_id",
        sortable: false,
        text: "Nama Bibit",
        value: "rel_tree_id",
    },
    {
        key: "amount",
        sortable: false,
        text: "Jumlah Bibit",
        value: "amount",
    },
]