export default [
    {
      key: "expanded-item",
      text: "Detail Label",
      sortable: false,
      key: "expanded-item",
    },
    {
      key: "detail_actions",
      text: "Opsi",
      sortable: false,
      value: "detail_actions",
    },
    {
      key: "index",
      sortable: false,
      text: "No",
      value: "index",
    },
    {
      key: "lahan_no",
      sortable: false,
      text: "Nomor Lahan",
      value: "lahan_no",
    },
    {
      key: "farmer_name",
      sortable: false,
      text: "Nama Petani",
      value: "farmer_name",
    },
    {
      key: "ff_name",
      sortable: false,
      text: "Nama FF",
      value: "ff_name",
    },
    {
      key: "mu_name",
      sortable: false,
      text: "Management Unit",
      value: "mu_name",
    },
    {
      key: "ff_name",
      sortable: false,
      text: "Pengemudi",
      value: "ff_name",
    },
    {
      key: "user_accepted",
      sortable: false,
      text: "Penerima",
      value: "user_accepted",
    },
    {
      key: "acceptance_progress",
      sortable: false,
      text: "Progress Penerimaan",
      value: "acceptance_progress",
    },
    {
      key: "total_pupuk",
      sortable: false,
      text: "Total Pupuk",
      value: "total_pupuk",
    },
    {
      key: "distribution_date",
      sortable: false,
      text: "Tanggal Distribusi",
      value: "distribution_date",
    }
]